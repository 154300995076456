import { SERVICE_WEATHER_STATE_COUNTERS, WEATHER_ACTIONS_TYPES } from '@/constants';

export default {
  grey: 'Gray',
  hideGrey: 'Hide gray',
  primaryIcon: 'Primary icon',
  secondaryIcon: 'Secondary icon',
  massActions: 'Mass actions',
  cannotBeApplied: 'This action cannot be applied',
  actions: {
    [WEATHER_ACTIONS_TYPES.entityAck]: 'Acknowledge',
    [WEATHER_ACTIONS_TYPES.entityAckRemove]: 'Cancel acknowledge',
    [WEATHER_ACTIONS_TYPES.entityValidate]: 'Validate',
    [WEATHER_ACTIONS_TYPES.entityInvalidate]: 'Invalidate',
    [WEATHER_ACTIONS_TYPES.entityPause]: 'Pause',
    [WEATHER_ACTIONS_TYPES.entityPlay]: 'Play',
    [WEATHER_ACTIONS_TYPES.entityCancel]: 'Cancel',
    [WEATHER_ACTIONS_TYPES.entityAssocTicket]: 'Associate ticket',
    [WEATHER_ACTIONS_TYPES.entityComment]: 'Comment',
    [WEATHER_ACTIONS_TYPES.executeInstruction]: 'Execute instruction',
    [WEATHER_ACTIONS_TYPES.declareTicket]: 'Declare ticket',
  },
  iconTypes: {
    ok: 'Ok',
    minorOrMajor: 'Minor or Major',
    critical: 'Critical',
  },
  stateCounters: {
    [SERVICE_WEATHER_STATE_COUNTERS.all]: 'Number of alarms',
    [SERVICE_WEATHER_STATE_COUNTERS.active]: 'Number of active alarms',
    [SERVICE_WEATHER_STATE_COUNTERS.depends]: 'Number of dependencies',
    [SERVICE_WEATHER_STATE_COUNTERS.ok]: 'Ok',
    [SERVICE_WEATHER_STATE_COUNTERS.minor]: 'Minor',
    [SERVICE_WEATHER_STATE_COUNTERS.major]: 'Major',
    [SERVICE_WEATHER_STATE_COUNTERS.critical]: 'Critical',
    [SERVICE_WEATHER_STATE_COUNTERS.acked]: 'Acknowledged',
    [SERVICE_WEATHER_STATE_COUNTERS.unacked]: 'Not acknowledged',
    [SERVICE_WEATHER_STATE_COUNTERS.underPbehavior]: 'Under PBh',
    [SERVICE_WEATHER_STATE_COUNTERS.ackedUnderPbehavior]: 'Acknowledged under PBh',
  },
  stateCountersTooltips: {
    [SERVICE_WEATHER_STATE_COUNTERS.all]: 'alarms total',
    [SERVICE_WEATHER_STATE_COUNTERS.active]: 'active alarms',
    [SERVICE_WEATHER_STATE_COUNTERS.depends]: 'dependencies',
    [SERVICE_WEATHER_STATE_COUNTERS.ok]: 'OK state',
    [SERVICE_WEATHER_STATE_COUNTERS.minor]: 'minor alarms',
    [SERVICE_WEATHER_STATE_COUNTERS.major]: 'major alarms',
    [SERVICE_WEATHER_STATE_COUNTERS.critical]: 'critical alarms',
    [SERVICE_WEATHER_STATE_COUNTERS.acked]: 'alarms acked',
    [SERVICE_WEATHER_STATE_COUNTERS.unacked]: 'not acked',
    [SERVICE_WEATHER_STATE_COUNTERS.underPbehavior]: 'under PBh',
    [SERVICE_WEATHER_STATE_COUNTERS.ackedUnderPbehavior]: 'acked under PBh',
  },
};
