export default {
  messages: {
    _default: 'The value is not valid',
    after: 'The must be after {1}',
    after_with_inclusion: 'The must be after or equal to {1}',
    alpha: 'The field may only contain alphabetic characters',
    alpha_dash: 'The field may contain alpha-numeric characters as well as dashes and underscores',
    alpha_num: 'The field may only contain alpha-numeric characters',
    alpha_spaces: 'The field may only contain alphabetic characters as well as spaces',
    before: 'The must be before {1}',
    before_with_inclusion: 'The must be before or equal to {1}',
    between: 'The field must be between {1} and {2}',
    confirmed: 'The confirmation does not match',
    credit_card: 'The field is invalid',
    date_between: 'The must be between {1} and {2}',
    date_format: 'The must be in the format {1}',
    decimal: 'The field must be numeric and may contain {1} decimal points',
    digits: 'The field must be numeric and contains exactly {1} digits',
    dimensions: 'The field must be {1} pixels by {2} pixels',
    email: 'The field must be a valid email',
    excluded: 'The field must be a valid value',
    ext: 'The field must be a valid file',
    image: 'The field must be an image',
    included: 'The field must be a valid value',
    integer: 'The field must be an integer',
    ip: 'The field must be a valid ip address',
    ip_or_fqdn: 'The field must be a valid ip address or FQDN',
    length: 'The length must be {1}',
    max: 'The field may not be greater than {1} characters',
    max_value: 'The field must be {1} or less',
    mimes: 'The field must have a valid file type',
    min: 'The field must be at least {1} characters',
    min_value: 'The field must be {1} or more',
    numeric: 'The field may only contain numeric characters',
    regex: 'The field format is invalid',
    required: 'The field is required',
    required_if: 'The field is required when the {1} field has this value',
    size: 'The size must be less than {1}KB',
    url: 'The field is not a valid URL',
  },
  custom: {
    tstop: {
      after: 'End time should be later than {1}',
    },
    logo: {
      size: 'The {0} size must be less than {1} KB.',
    },
  },
};
