export default {
  sharedViewUrl: 'Shared view url',
  shareView: 'Share view {name}',
  deleteRow: 'Delete row',
  deleteWidget: 'Delete widget',
  fullScreen: 'Full screen',
  fullScreenShortcut: 'Alt + Enter / Command + Enter',
  copyWidgetId: 'Copy widget ID',
  autoHeightButton: 'If this button is selected, height will be automatically calculated.',
  selectAll: 'Select all groups and views',
  duplicateAsPrivate: 'Duplicate as private view',
  duplicateAsRegular: 'Duplicate as regular view',
  periodicRefresh: 'Periodic refresh',
  groupIds: 'Choose a group, or create a new one',
  groupTags: 'Group tags',
  noGroupsFound: 'No group corresponding. Press <kbd>enter</kbd> to create a new one',
  errors: {
    emptyTabs: 'You should create a tab',
  },
};
