export default {
  selector: {
    defaultFilter: 'Filtre par défaut',
    fields: {
      mixFilters: 'Combiner les filtres (ET)',
    },
    buttons: {
      list: 'Gérer les filtres',
    },
  },
};
