export default {
  selector: {
    defaultFilter: 'Default filter',
    fields: {
      mixFilters: 'Mix filters',
    },
    buttons: {
      list: 'Manage filters',
    },
  },
};
