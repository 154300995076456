export default {
  messages: {
    _default: "Le champ n'est pas valide",
    after: 'Le champ doit être postérieur à {1}',
    after_with_inclusion: 'Le champ doit être postérieur ou égal à {1}',
    alpha: 'Le champ ne peut contenir que des lettres',
    alpha_dash: 'Le champ ne peut contenir que des caractères alpha-numériques, tirets ou soulignés',
    alpha_num: 'Le champ ne peut contenir que des caractères alpha-numériques',
    alpha_spaces: 'Le champ ne peut contenir que des lettres ou des espaces',
    before: 'Le champ doit être antérieur à {1}',
    before_with_inclusion: 'Le champ doit être antérieur ou égal à {1}',
    between: 'Le champ doit être compris entre {1} et {2}',
    confirmed: 'Le champ ne correspond pas à {1}',
    credit_card: 'Le champ est invalide',
    date_between: 'Le champ doit être situé entre {1} et {2}',
    date_format: 'Le champ doit être au format {1}',
    decimal: 'Le champ doit être un nombre et peut contenir {1} décimales',
    digits: 'Le champ doit être un nombre entier de {1} chiffres',
    dimensions: 'Le champ doit avoir une taille de {1} pixels par {2} pixels',
    email: 'Le champ doit être une adresse e-mail valide',
    excluded: 'Le champ doit être une valeur valide',
    ext: 'Le champ doit être un fichier valide',
    image: 'Le champ doit être une image',
    included: 'Le champ doit être une valeur valide',
    integer: 'Le champ doit être un entier',
    ip: 'Le champ doit être une adresse IP',
    ip_or_fqdn: 'Le champ doit être une adresse IP ou un nom de domaine valide',
    length: 'Le champ doit contenir {1} caractères',
    max: 'Le champ ne peut pas contenir plus de {length} caractères',
    max_value: 'Le champ doit avoir une valeur de {max} ou moins',
    mimes: 'Le champ doit avoir un type MIME valide',
    min: 'Le champ doit contenir au minimum {1} caractères',
    min_value: 'Le champ doit avoir une valeur de {1} ou plus',
    numeric: 'Le champ ne peut contenir que des chiffres',
    regex: 'Le champ est invalide',
    required: 'Le champ est obligatoire',
    required_if: 'Le champ est obligatoire lorsque {1} possède cette valeur',
    size: 'Le champ doit avoir un poids inférieur à {1} Ko',
    url: 'Le champ n\'est pas une URL valide',
  },
  custom: {
    tstop: {
      after: 'La date de fin doit être postérieure à {1}',
    },
    logo: {
      size: 'La taille {0} doit être inférieure à {1} Ko.',
    },
  },
};
